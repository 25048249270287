.top-block-custom {
    .container {
        background: #F5F6F9;
        border-radius: 80px;
        @media (max-width: 1399px) {
            padding: 80px 100px;
        }
        @media (max-width: 767px) {
            padding: 40px 60px;
            border-radius: 0;
        }
        @media (max-width: 575px) {
            padding: 40px 15px;
        }
        @media (min-width: 1400px) {
            padding: 80px 150px;
        }
        @media (min-width: 1800px) {
            padding: 120px 220px;
        }
        .block-wrap {
            display: flex;
            gap: 40px;
            @media (max-width: 991px) {
                flex-direction: column;
                gap: 80px;
            }
            .block {
                flex: 0 0 50%;
                position: relative;
                .image-bg-1 {
                    position: absolute;
                    top: 0;
                    right: 15px;
                    @media (max-width: 991px) {
                        right: 0;
                    }
                    @media (max-width: 575px) {
                        display: none;
                    }
                    @media (min-width: 1400px) {
                        right: 15px;
                    }
                    @media (min-width: 1800px) {
                        right: 150px;
                    }
                    &.image-bg-2 {
                        bottom: 31px;
                        top: initial;
                        left: -30px;
                    }
                    &.image-bg-3 {
                        bottom: 150px;
                        top: initial;
                        @media (max-width: 1399px) {
                            right: -60px;
                            bottom: 50px;
                        }
                        @media (min-width: 1400px) {
                            right: -100px;
                        }
                        @media (min-width: 1800px) {
                            right: -150px;
                        }
                    }
                }
            }
        }
    }
    .subtitle {
        font-size: 16px;
        font-style: normal !important;
        font-weight: 450;
        line-height: 150%;
        text-transform: uppercase;
        font-family: 'BrandonText';
        margin-bottom: 12px;
    }
    .title {
        h1 {
            font-size: 64px;
            @media (max-width: 1399px) {
                font-size: 34px;
            }
            font-style: normal;
            font-weight: 450;
            line-height: 130%;
            color: #000;
        }
    }
    .btn-wrap {
        display: flex;
        gap: 20px;
        margin-top: 30px;
        .btn {
            min-width: 140px;
            text-align: center;
        }
    }
    .bottom-block {
        position: relative;
        margin-top: 126px;
        @media (max-width: 575px) {
            margin-top: 25px;
        }
        .reviews-block {
            position: absolute;
            border-radius: 30px;
            padding: 20px;
            top: -85px;
            right: 0;
            background: #fff;
            z-index: 2;
            @media (max-width: 575px) {
                position: static;
                margin-bottom: 15px;
            }
            .numbers {
                font-size: 36px;
                font-style: normal;
                font-weight: 450;
                line-height: 140%;
                color: #000;
                font-family: $font-primary;
                @media (max-width: 1399px) {
                    font-size: 26px;
                }
            }
            .text {
                font-family: $font-primary;
                font-size: 15px;
                font-style: normal;
                font-weight: 390;
                line-height: 140%;
            }
        }
        .live-img-wrap {
            padding: 0 50px;
            border-radius: 60px;
            background: #E6EBF3;
            margin-right: 110px;
            @media (max-width: 1199px) {
                margin-right: 0;
            }
        }
        .study-today-block {
            border-radius: 30px;
            background: #fff;
            box-shadow: 0px 10.022222518920898px 25.82962989807129px 0px rgba(0, 0, 0, 0.03), 0px 47.377777099609375px 100.97036743164062px 0px rgba(0, 0, 0, 0.04), 0px 123px 317px 0px rgba(0, 0, 0, 0.07);
            font-family: $font-primary;
            font-size: 18px;
            font-style: normal;
            font-weight: 450;
            line-height: 120%;
            position: absolute;
            left: -135px;
            bottom: 40px;
            padding: 16px 20px;
            @media (max-width: 1399px) {
                left: -80px;
            }
            @media (max-width: 767px) {
                left: 0;
                bottom: 0;
            }
        }
    }
    .top-block {
        position: relative;
        .program {
            position: absolute;
            z-index: 2;
            padding: 20px;
            top: -36px;
            right: -63px;
            border-radius: 30px;
            background: #FFF;
            box-shadow: 0px 10.022222518920898px 25.82962989807129px 0px rgba(0, 0, 0, 0.03), 0px 47.377777099609375px 100.97036743164062px 0px rgba(0, 0, 0, 0.04), 0px 123px 317px 0px rgba(0, 0, 0, 0.07);
            @media (max-width: 1399px) {
                right: -25px;
            }
            @media (max-width: 575px) {
                position: static;
                margin-bottom: 15px;
                box-shadow: none;
            }
            .block-title {
                color: #9C9C9C;
                font-family: $font-primary;
                font-size: 18px;
                font-style: normal;
                font-weight: 450;
                line-height: 150%;
                text-transform: uppercase;
                margin-bottom: 25px;
            }
            img {
                width: 24px;
                height: 24px;
            }
            .item {
                display: flex;
                align-items: center;
                gap: 12px;
                margin-bottom: 16px;
                &:last-child {
                    margin-bottom: 0;
                }
                .text {
                    color: #000;
                    font-family: $font-primary;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 390;
                    line-height: 160%;
                }
            }
        }
        .live-img-wrap {
            border-radius: 60px;
            background: #e6ebf3;
            max-width: 620px;
            min-height: 619px;
            display: flex;
            align-items: center;
            justify-content: center;
            @media (max-width: 575px) {
                min-height: auto;
            }
            #lottie_1 {
                width: 100%;
            }
        }
    }
    .text-top-block {
        margin-top: 40px;
        font-size: 20px;
        font-style: normal;
        font-weight: 390;
        line-height: 160%;
        font-family: $font-primary;
    }
    .sign-up-top-block {
        font-family: $font-primary;
        font-size: 18px;
        font-style: normal;
        font-weight: 450;
        line-height: 150%;
        margin-top: 24px;
        a {
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }
    &.top-block-product {
        .absolute-block {
            display: flex;
            gap: 8px;
            border-radius: 40px;
            background: #FFF;
            padding: 16px;
            align-items: center;
            position: absolute;
            @media (max-width: 575px) {
                position: static;
                margin-top: 15px;
            }
            &.absolute-block-1 {
                top: 108px;
                right: -77px;
                @media (max-width: 767px) {
                    top: 50px;
                    right: -40px;
                }
            }
            &.absolute-block-2 {
                bottom: 250px;
                left: -85px;
                @media (max-width: 767px) {
                    left: -40px;
                }
            }
            &.absolute-block-3 {
                bottom: 50px;
                right: -77px;
                @media (max-width: 767px) {
                    right: -40px;
                }
            }
        }
        #lottie {
            svg {
                padding: 44px;
            }
        }
        .text-top-block {
            font-family: inherit;
        }
        .container {
            .block-wrap {
                align-items: center;
                @media (max-width: 991px) {
                    flex-direction: column-reverse;
                }
                .block {
                    &:nth-child(2) {
                        margin-bottom: 95px;
                        padding-left: 150px;
                        @media (max-width: 1199px) {
                            padding-left: 100px;
                        }
                        @media (max-width: 991px) {
                            padding-left: 0;
                            margin-bottom: 0;
                        }
                    }
                    .text-after-title {
                        font-size: 28px;
                        font-style: normal;
                        font-weight: 450;
                        line-height: 140%;
                        color: #000;
                        margin-top: 12px;
                        strong {
                            color: #5233EC;
                        }
                    }
                }
            }
        } 
    }
}
.slider-custom {
    padding-top: 200px;
    overflow: hidden;
    @media (max-width: 991px) {
        padding-top: 100px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .title-box {
        .subtitle {
            font-family: $font-primary;
            font-size: 16px;
            font-style: normal !important;
            font-weight: 450;
            line-height: 150%;
            text-transform: uppercase;
            margin-bottom: 12px;
        }
        .title {
            h2 {
                color:  #000;
                /* h2 */
                font-family: $font-primary;
                font-size: 56px;
                font-style: normal;
                font-weight: 450;
                line-height: 120%;
                margin-bottom: 16px;
                @media (max-width: 991px) {
                    font-size: 36px;
                }
            }
        }
        .text {
            color: #292929;
            // font-family: $font-primary;
            font-size: 20px;
            font-style: normal;
            font-weight: 390;
            line-height: 160%;
        }
    }
    .swiper-button-next:after, .swiper-button-prev:after {
        display: none;
    }
    .swiper-button-next, .swiper-button-prev {
        position: static;
        border: 1px solid #000;
        border-radius: 20px;
        width: 58px;
        height: 58px;
        svg {
            width: 24px;
            height: 24px;
            path {
                fill: #000;
            }
        }
        &:hover {
            background: #000;
            svg {
                path {
                    fill: #fff;
                }
            }
        }
    }
    .slider-btn-wrap {
        display: flex;
        gap: 25px;
    }
    .title-box-wrap {
        display: flex;
        justify-content: space-between;
        align-items: self-end;
        margin-bottom: 60px;
        @media (max-width: 991px) {
            flex-direction: column;
            gap: 50px;
            align-items: flex-start;
        }
        .title-box {
            width: 740px;
            @media (max-width: 991px) {
                width: auto;
            }
        }
    }
    .swiper-slide {
        border-radius: 60px;
        padding: 60px;
        .img-box {
            border-radius: 70px;
            background-color: #fff;
            padding: 10px;
            width: 80px;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 24px;
            img {
                width: 50px;
                height: 50px;
            }
        }
        .title-box {
            font-family: $font-primary;
            font-size: 28px;
            font-style: normal;
            font-weight: 450;
            line-height: 140%;
            color: #000;
        }
        .text-box {
            color: #000;
            // font-family: $font-primary;
            font-size: 16px;
            font-style: normal;
            font-weight: 390;
            line-height: 160%;
            margin-top: 12px;
        }
    }
    .mySwiper {
        margin-right: calc(-1*(100vw - 1280px + 30px)/2);
        width: calc(1*(100vw - 1280px + 30px)/2 + 100%);
        overflow: hidden;
        @media (max-width: 1399px) {
            margin-right: calc(-1*(100vw - 1140px + 30px)/2);
            width: calc(1*(100vw - 1140px + 30px)/2 + 100%);
        }
        @media (max-width: 1199px) {
            margin-right: 0;
            width: auto;
        }
        .swiper-wrapper {
            padding: 20px 0;
        }
        .swiper-slide {
            &:nth-child(odd) {
                transform: rotate(2deg);
            }
            &:nth-child(even) {
                transform: rotate(-2deg);
            }
        }
    }
    &.slider_reviews {
        .swiper-slide {
            padding: 0;
            border-radius: 0;
            display: flex;
            gap: 100px;
            align-items: center;
            @media (max-width: 991px) {
                flex-direction: column;
                gap: 25px;
            }
            @media (max-width: 575px) {
                display: block;
            }
            .img-box {
                width: 440px;
                height: 440px;
                padding: 0;
                margin-bottom: 0;
                flex: 0 0 400px;
                position: relative;
                @media (max-width: 575px) {
                    width: 100%;
                    height: auto;
                }
                img {
                    width: auto;
                    height: auto;
                }
                .flag-bg {
                    background: #fff;
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid #F5F6F9;
                    top: 15px;
                    right: 10px;
                    @media (max-width: 575px) {
                        top: -15px;
                    }
                    img {
                        width: 32px;
                    }
                }
            }
            .text-box {
                margin-top: 0;
                font-family: $font-primary;
                font-size: 30px;
                font-style: normal;
                font-weight: 450;
                line-height: 140%;
                display: flex;
                flex-direction: column;
                gap: 40px;
                @media (max-width: 575px) {
                    font-size: 16px;
                    padding-top: 10px;
                } 
                .name {
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 450;
                    line-height: 150%;
                }
                .country {
                    color: #292929;
                    /* body 3 */
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 390;
                    line-height: 160%;
                    font-family: $font-primary;
                }
                @media (max-width: 575px) {
                    .name-box {
                        margin-bottom: 50px;
                    }
                }
            }
        }
        .swiper-pagination-fraction {
            // position: static;
            display: flex;
            color: #9C9C9C;
            font-size: 30px;
            font-style: normal;
            font-weight: 450;
            line-height: 140%;
            justify-content: center;
            font-family: $font-primary;
            .swiper-pagination-current {
                color: #000;
            }
        }
    }
}
.what-we-offer {
    padding-top: 200px;
    text-align: center;
    @media (max-width: 991px) {
        padding-top: 100px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .subtitle {
        font-family: $font-primary;
        font-size: 16px;
        font-style: normal !important;
        font-weight: 450;
        line-height: 150%;
        text-transform: uppercase;
        margin-bottom: 12px;
    }
    .title {
        h2 {
            color:  #000;
            /* h2 */
            font-family: $font-primary;
            font-size: 56px;
            font-style: normal;
            font-weight: 450;
            line-height: 120%;
            margin-bottom: 16px;
            @media (max-width: 991px) {
                font-size: 36px;
            }
        }
    }
    .text {
        color: #292929;
        // font-family: $font-primary;
        font-size: 20px;
        font-style: normal;
        font-weight: 390;
        line-height: 160%;
        max-width: 740px;
        margin: auto;
    }
    .block-wrap-custom {
        margin-top: 60px;
        display: flex;
        gap: 40px;
        @media (max-width: 1199px) {
            flex-direction: column;
        }
        .block {
            flex: 0 0 calc(33.3333% - 27px);
            border-radius: 80px;
            background-color: #FAFAFA;
            padding: 40px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            &:hover {
                background-color: #c6daf9;
            }
            &:nth-child(even) {
                flex-direction: column-reverse;
            }
            .title-block {
                h5 {
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 450;
                    line-height: 140%;
                    color: #000;
                }
            }
            .text-block {
                margin-top: 12px;
                font-size: 16px;
                font-weight: 390;
                line-height: 160%;
            }
        }
        .btn-wrap {
            margin-top: 24px;
            .btn {
                font-size: 18px;
                font-weight: 450;
                font-family: $font-primary;
                // padding: 16px 32px;
                padding: 10px;
            }
        }
    }
}
.oscestop-platform {
    padding-top: 200px;
    @media (max-width: 991px) {
        padding-top: 100px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .subtitle {
        font-family: $font-primary;
        font-size: 16px;
        font-style: normal !important;
        font-weight: 450;
        line-height: 150%;
        text-transform: uppercase;
        margin-bottom: 12px;
    }
    .title {
        h2 {
            color:  #000;
            font-family: $font-primary;
            font-size: 56px;
            font-style: normal;
            font-weight: 450;
            line-height: 120%;
            margin-bottom: 16px;
            @media (max-width: 991px) {
                font-size: 36px;
            }
        }
    }
    .text {
        color: #292929;
        font-size: 20px;
        font-style: normal;
        font-weight: 390;
        line-height: 160%;
        max-width: 740px;
        margin: auto;
    }
    .title-block {
        max-width: 839px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
    .platform-wrap {
        margin-top: 90px;
        display: flex;
        @media (max-width: 991px) {
            flex-direction: column-reverse;  
            gap: 15px; 
        }
        .info-blocks,
        .img-box {
            flex: 0 0 50%;
        }
        .img-box {
            padding: 95px 48px;
            border-radius: 80px;
            background-color: #FAFAFA;
            display: flex;
            align-items: center;
            justify-content: center;
            @media (max-width: 991px) {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0;
            }
        }
        .info-blocks {
            padding-right: 110px;
            @media (max-width: 991px) {
                padding-right: 0;
            }
            .number {
                font-size: 28px;
                font-style: normal;
                font-weight: 450;
                line-height: 140%;
                font-family: $font-primary;
            }
            .title {
                font-size: 28px;
                font-style: normal;
                font-weight: 450;
                line-height: 140%;
                font-family: $font-primary;
                margin: 12px 0;
            }
            .text {
                font-size: 18px;
                font-style: normal;
                font-weight: 390;
                line-height: 150%;
                color: #292929;
            }
            .block {
                margin-bottom: 32px;
                &:last-child() {
                    margin-bottom: 0;
                }
            }
        }
    }
}
.timeline {
    padding-top: 200px;
    @media (max-width: 991px) {
        padding-top: 100px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .subtitle {
        font-family: $font-primary;
        font-size: 16px;
        font-style: normal !important;
        font-weight: 450;
        line-height: 150%;
        text-transform: uppercase;
        margin-bottom: 12px;
    }
    .title {
        h2 {
            color:  #000;
            font-family: $font-primary;
            font-size: 56px;
            font-style: normal;
            font-weight: 450;
            line-height: 120%;
            margin-bottom: 16px;
            @media (max-width: 991px) {
                font-size: 36px;
            }
        }
    }
    .text {
        color: #292929;
        font-size: 20px;
        font-style: normal;
        font-weight: 390;
        line-height: 160%;
    }
    .title-block {
        display: flex;
        align-items: end;
        gap: 113px;
        @media (max-width: 991px) {
            flex-direction: column;
            gap: 15px;
            align-items: flex-start;
        }
    }
    .info {
        flex: 0 0 740px;
        @media (max-width: 991px) {
            flex: 0 0 100%;
        }
    }
    .timeline-block {
        margin-top: 60px;
        ul {
            width: 900px;
            margin: auto;
            li {
                width: 1px;
                padding-top: 50px;
                background: linear-gradient(90deg, #5232ED 73.42%, rgba(82, 50, 237, 0.00) 100%);
                position: relative;
                &:last-child {
                    background: transparent;
                }
                .item-custom {
                    // transform: translate3d(0, 150px, 0);
                    transform: translate3d(0, 0px, 0);
                    position: relative;
                    bottom: 0;
                    visibility: visible;
                    opacity: 0.2;
                    transition: all 0.5s ease-in-out;
                    left: 45px;
                    width: 800px;
                    top: -60px;
                    @media (max-width: 991px) {
                        transform: none;
                        visibility: visible;
                        opacity: 1;
                        width: 500px;
                    }
                    @media (max-width: 575px) {
                        width: 230px;
                    }
                    .title {
                        h5 {
                            color: #000;
                            /* h5 */
                            font-family: $font-primary;
                            font-size: 28px;
                            font-style: normal;
                            font-weight: 450;
                            line-height: 140%;
                            @media (max-width: 575px) {
                                font-size: 18px;
                            }
                        }
                    }
                    .text {
                        color: #292929;
                        /* body 3 */
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 390;
                        line-height: 160%;
                    }
                }
                &::after {
                    content: "";
                    position: absolute;
                    left: 50%;
                    top: 0;
                    transform: translateX(-50%);
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    background: url('../img/timeline.svg');
                    z-index: 1;
                    // transition: background 0.5s ease-in-out;
                }
                &.in-view {
                    .item-custom {
                        transform: none;
                        visibility: visible;
                        opacity: 1;
                    }
                    &::after {
                        background: url('../img/timeline-active.svg');
                    }
                    &.active {
                        &::after {
                            background: url('../img/timeline-active-1.svg');
                            width: 50px;
                            height: 50px;
                            background-repeat: no-repeat;
                            top: -15px;
                        }
                    }
                }
            }
        }
    }
}
.result {
    padding-top: 200px;
    overflow: hidden;
    @media (max-width: 991px) {
        padding-top: 100px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .subtitle {
        font-family: $font-primary;
        font-size: 16px;
        font-style: normal !important;
        font-weight: 450;
        line-height: 150%;
        text-transform: uppercase;
        margin-bottom: 12px;
    }
    .title {
        h2 {
            color:  #000;
            font-family: $font-primary;
            font-size: 56px;
            font-style: normal;
            font-weight: 450;
            line-height: 120%;
            margin-bottom: 16px;
            @media (max-width: 991px) {
                font-size: 36px;
            }
        }
    }
    .text {
        color: #292929;
        font-size: 20px;
        font-style: normal;
        font-weight: 390;
        line-height: 160%;
    }
    .title-block {
        text-align: center;
    }
    // .single-chart {
    //     width: 33%;
    //     justify-content: space-around ;
    // }
    .circular-chart {
        display: block;
        margin: 10px auto;
        max-width: 80%;
        max-height: 220px;
    }
    .circle-bg {
        fill: none;
        stroke: #FFBA28;
        stroke-width: 2.8;
    }
    .circle {
        fill: none;
        stroke-width: 2.8;
        stroke-linecap: round;
        &.start {
            animation: progress 1s ease-out forwards;
        }
    }
    @keyframes progress {
        0% {
            stroke-dasharray: 0 100;
        }
    }
    .circular-chart.blue .circle {
        stroke: #120667;
    }
    .percentage {
        fill: #000;
        text-anchor: middle;
        font-family: $font-primary;
        font-size: 8px;
        font-style: normal;
        font-weight: 450;
        line-height: 140%;
    }
    .block-wrap {
        display: flex;
        gap: 40px;
        margin-top: 60px;
        @media (max-width: 1399px) {
            flex-direction: column;
        }
        .block {
            flex: 0 0 calc(50% - 20px);
        }
        .block-1 {
            transform: rotate(-1.5deg);
            border-radius: 80px;
            background: #F1F5FB;
            padding: 70px 66px;
            @media (max-width: 575px) {
                transform: none;
                border-radius: 15px;
                padding: 15px;
            }
            .title-block {
                font-family: $font-primary;
                font-size: 30px;
                font-style: normal;
                font-weight: 450;
                line-height: 140%;
                text-align: left;
                transform: rotate(1.5deg);
                color: #121212;
                @media (max-width: 575px) {
                    transform: none;
                }
            }
            .info-wrap {
                display: flex;
                transform: rotate(1.5deg);
                margin-top: 60px;
                @media (max-width: 767px) {
                    flex-direction: column;
                }
                @media (max-width: 575px) {
                    transform: none;
                    margin-top: 0;
                }
                .single-chart,
                .info {
                    flex: 0 0 50%;
                }
                .text-block {
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 450;
                    line-height: 140%;
                    color: #000;
                    font-family: $font-primary;
                }
            }
            .great-block {
                display: flex;
                flex-direction: column;
                margin-top: 30px;
                gap: 12px;
                .item {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 390;
                    line-height: 160%;
                    color: #000;
                }
            }
        }
        .block-2 {
            border-radius: 80px;
            background: #D8E7FF;
            transform: rotate(1.5deg);
            padding: 70px 66px;
            @media (max-width: 575px) {
                transform: none;
                padding: 15px;
                border-radius: 15px;
            }
            @keyframes rot_m {
                from {
                    transform: rotate(0deg)
                               translateY(92px)
                               rotate(0deg);
                               left: initial;
                               top: initial;
                }
                to {
                    transform: rotate(180deg)
                               translateY(92px) 
                               rotate(-180deg);
                               left: initial;
                               top: initial;
                }
            }
            @keyframes rot {
                from {
                    transform: rotate(0deg)
                               translate(92px)
                               rotate(0deg);
                               left: initial;
                }
                to {
                    transform: rotate(180deg)
                               translate(92px) 
                               rotate(-180deg);
                               left: initial;
                }
            }
            @keyframes rot_1 {
                from {
                    transform: rotate(0deg)
                               translate(-92px)
                               rotate(0deg);
                               right: initial;
                }
                to {
                    transform: rotate(180deg)
                               translate(-92px) 
                               rotate(-180deg);
                               right: initial;
                }
            }
            @keyframes rot_1_m {
                from {
                    transform: rotate(0deg)
                               translateY(-92px)
                               rotate(0deg);
                               right: initial;
                               bottom: initial;
                }
                to {
                    transform: rotate(180deg)
                               translateY(-92px) 
                               rotate(-180deg);
                               right: initial;
                               bottom: initial;
                }
            }
            .left-text {
                color: #000;
                text-align: center;
                font-family: $font-primary;
                font-size: 18px;
                font-style: normal;
                font-weight: 450;
                line-height: 150%;
                padding: 18px 26px;
                background: #fff;
                border-radius: 24px;
                position: relative;
                strong {
                    color: #FFD141;
                }
                &::after {
                    content: '';
                    width: 45px;
                    height: 6px;
                    background: url('../img/rot-bg.png');
                    display: block;
                    position: absolute;
                    right: -33px;
                    transform: rotate(180deg);
                    top: 40px;
                    @media (max-width: 1399px) {
                        top: 30px;
                    }
                    @media (min-width: 1800px) {
                        top: 30px;
                    }
                    @media (max-width: 767px) {
                        display: none;
                    }
                }
            }
            .right-text {
                color: #000;
                text-align: center;
                font-family: $font-primary;
                font-size: 18px;
                font-style: normal;
                font-weight: 450;
                line-height: 150%;
                padding: 18px 26px;
                background: #fff;
                border-radius: 24px;
                position: relative;
                strong {
                    color: #15B768;
                }
                &::after {
                    content: '';
                    width: 45px;
                    height: 6px;
                    background: url('../img/rot-bg.png');
                    display: block;
                    position: absolute;
                    left: -33px;
                    // transform: rotate(180deg);
                    top: 40px;
                    @media (max-width: 1399px) {
                        top: 30px;
                    }
                    @media (min-width: 1800px) {
                        top: 30px;
                    }
                    @media (max-width: 767px) {
                        display: none;
                    }
                }
            }
            .icon-box {
                .icon {
                    padding: 10px;
                    border-radius: 50%;
                    background: #fff;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    @media (max-width: 991px) {
                        // display: none;
                        z-index: 2;
                    }
                    &.right-icon {
                        right: -60px;
                        &.start {
                            animation: rot_1 2s linear;
                            // animation: rot_1 3s infinite linear;
                            @media (max-width: 575px) {
                                animation: rot_1_m 2s linear;
                            }
                        }
                        // &.stop {
                        //     left: -60px;
                        // }
                        @media (max-width: 575px) {
                            right: 35px;
                            bottom: -60px;
                        }
                    }
                    &.left-icon {
                        left: -60px;
                        &.start {
                            animation: rot 2s linear;
                            @media (max-width: 575px) {
                                animation: rot_m 2s linear;
                            }
                            // right: 0;
                        }
                        // &.stop {
                        //     right: -60px;
                        // }
                        @media (max-width: 575px) {
                            left: 35px;
                            top: -60px;
                        }
                    }
                }
            }
            .top-block {
                display: flex;
                align-items: center;
                transform: rotate(-1.5deg);
                justify-content: center;
                gap: 60px;
                @media (max-width: 991px) {
                    gap: 33px;
                }
                @media (max-width: 575px) {
                    transform: none;
                    flex-direction: column;
                }
                .center-block {
                    border-radius: 50%;
                    border: 30px solid #e4eeff;
                    color: #000;
                    font-family: $font-primary;
                    text-align: center;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 450;
                    line-height: 150%;
                    width: 180px;
                    height: 180px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    @media (max-width: 575px) {
                        &.start {
                            .right-icon {
                                animation: rot_1_m 3s linear;
                            }
                            .left-icon {
                                animation: rot_m 3s linear;
                            }
                        }
                    }
                }
            }
            .info-block {
                transform: rotate(-1.5deg);
                margin-top: 48px;
                color: #000;
                font-family: $font-primary;
                font-size: 30px;
                font-style: normal;
                font-weight: 450;
                line-height: 140%;
                @media (max-width: 575px) {
                    transform: none;
                    font-size: 21px;
                }
                a {
                    color: #5233EC;
                }
            }
        }
    }
}
.lines {
    padding-top: 200px;
    padding-bottom: 20px;
    overflow: hidden;
    @media (max-width: 991px) {
        padding-top: 100px;
    }
    .title {
        h3 {
            color: #000;
            /* h3 */
            font-size: 40px;
            font-style: normal;
            font-weight: 450;
            line-height: 120%;
            opacity: 0.699999988079071;
            @media (max-width: 991px) {
                font-size: 30px;
            }
        }
        @media (max-width: 991px) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    .line {
        font-size: 30px;
        font-style: normal;
        font-weight: 450;
        line-height: 150%;
        text-transform: uppercase;
        gap: 50px;
        font-family: $font-primary;
        padding: 20px 0;
        overflow: hidden;
        .line-wrap {
            word-break: break-all;
            white-space: nowrap;
            display: flex;
            overflow: hidden;
            // flex-wrap: wrap;
            gap: 50px;
            // justify-content: space-between;
            // .line-item {
            //     display: inline-block;
            // }
        }
        @media (max-width: 991px) {
            font-size: 16px;
            gap: 15px;
            white-space: normal;
            word-break: normal;
            padding-left: 15px;
            padding-right: 15px;
            text-align: center;
        }
    }
    .line-1 {
        transform: rotate(-1deg);
        color: #fff;
        margin-top: 60px;
        @media (max-width: 991px) {
            transform: none;
        }
    }
    .line-2 {
        color: #000;
        transform: rotate(1deg);
        margin-top: 30px;
        // float: right;
        @media (max-width: 991px) {
            transform: none;
        }
    }
}
.ready {
    margin-top: 200px;
    @media (max-width: 991px) {
        margin-top: 100px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .ready-box {
        padding: 100px 0 106px;
        border-radius: 80px;
        background: #C7F5DE;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;
        @media (max-width: 991px) {
            padding: 25px 10px;
            gap: 15px;
            border-radius: 15px;
        }
        .btn {
            // padding: 16px 32px;
            padding: 10px;
        }
    }
    .subtitle {
        color: #000;
        /* tag */
        font-size: 16px;
        font-style: normal !important;
        font-weight: 450;
        line-height: 150%;
        text-transform: uppercase;
        text-align: center;
        font-family: $font-primary;
    }
    .title {
        h2 {
            color: #000;
            text-align: center;
            /* h2 */
            font-family: $font-primary;
            font-size: 56px;
            font-style: normal;
            font-weight: 450;
            line-height: 120%;
            @media (max-width: 991px) {
                font-size: 36px;
            }
        }
    }
}
.what-we-offer-slider {
    .swiper-container {
        margin-top: 60px;
        position: relative;
        &.active-scroll {
            height: 1000vh;
            position: relative;
            // top: 100px;
        }
        .slider-btn-wrap {
            position: absolute;
            top: 250px;
            z-index: 2;
            left: 60px;
            @media (max-width: 1199px) {
                top: 200px;
            }
            @media (max-width: 767px) {
                top: initial;
                bottom: 15px;
                left: calc(50% - 75px);
            }
        }
        .swiper-pagination {
            position: static;
            display: flex;
            flex-direction: column;
            gap: 14px;
            @media (max-width: 767px) {
                flex-direction: row;
            }
            .swiper-pagination-bullet {
                font-size: 18px;
                font-style: normal;
                font-weight: 390;
                line-height: 150%; 
                background: transparent;
                width: 32px;
                height: 32px;
                &.swiper-pagination-bullet-active {
                    color: #fff;
                }
                &:nth-child(1) {
                    &.swiper-pagination-bullet-active {
                        background: #15B768;
                    }
                }
                &:nth-child(2) {
                    &.swiper-pagination-bullet-active {
                        background: #FFC100;
                    }
                }
                &:nth-child(3) {
                    &.swiper-pagination-bullet-active {
                        background: #140867;
                    }
                }
            }
        }
        .new-block {
            position: absolute;
            right: 80px;
            top: 80px;
            background: #140867;
            display: flex;
            flex-direction: column;
            border-radius: 50%;
            align-items: center;
            padding: 15px;
            width: 90px;
            height: 90px;
            @media (max-width: 1399px) {
                right: 50px;
                top: 25px;
            }
            .icon {
                background-image: url('../img/new-icon.svg');
                width: 24px;
                height: 24px;
            }
            span {
                color: #FFF;
                font-family: $font-primary;
                font-size: 22px;
                font-style: normal;
                font-weight: 450;
                line-height: 150%; /* 33px */
                text-transform: uppercase;
            }
        }
    }
    .splide__slide {
        border-radius: 80px;
        padding: 100px 160px;
        // max-height: 500px;
        @media (min-width: 1400px) {
            padding: 100px 160px;
        }
        @media (min-width: 1800px) {
            padding: 100px 260px;
        }
        @media (max-width: 1399px) {
            padding-right: 50px;
        }
        @media (max-width: 767px) {
            padding: 50px;
            padding-bottom: 100px;
            border-radius: 20px;
        }
        @media (max-width: 575px) {
            padding: 50px 20px;
        }
        .slide-title {
            font-size: 40px;
            font-style: normal;
            font-weight: 450;
            line-height: 120%;
            font-family: $font-primary;
            text-align: left;
            @media (max-width: 575px) {
                font-size: 30px;
            }
        }
        .btn-wrap {
            display: flex;
            margin-top: 40px;
        }
        .btn {
            padding: 10px;
            font-weight: 450;
        }
        .m-accordions__item {
            .m-accordions__item__title {
                cursor: pointer;
                width: 100%;
                text-align: left;
                outline: none;
                transition: 0.4s;
                font-size: 20px;
                font-weight: 450;
                line-height: 160%;
                font-family: $font-primary;
                margin-top: 32px;
                display: flex;
                // justify-content: space-between;
                align-items: center;
                &::after {
                    content: '';
                    background-image: url('../img/arrow-faq.svg');
                    display: block;
                    width: 24px;
                    height: 24px;
                    margin-left: 5px;
                    flex: 0 0 24px;
                }
            }
            &.isActive {
                .m-accordions__item__title {
                    &::after {
                        transform: rotate(180deg);
                    }
                }
            }
        }
        .panel {
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.2s ease-out;
            ul {
                text-align: left;
                margin-left: 20px;
                margin-top: 24px;
                list-style-type: none !important;
            }
            li {
                width: 1px;
                padding-top: 16px;
                position: relative;
                list-style: none !important;
                @media (max-width: 575px) {
                    width: 100%;
                    background: transparent !important;
                }
                &::after {
                    content: "";
                    position: absolute;
                    left: 50%;
                    top: 0;
                    transform: translateX(-50%);
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    z-index: 1;
                    @media (max-width: 575px) {
                        left: 0;
                    }
                }
                &::marker {
                    display: none !important;
                }
                .item-custom {
                    position: relative;
                    bottom: 0;
                    transition: all .5s ease-in-out;
                    left: 45px;
                    width: 450px;
                    top: -20px;
                    font-size: 18px;
                    font-weight: 390;
                    line-height: 150%;
                    @media (max-width: 575px) {
                        width: 100%;
                        left: 0;
                        padding-left: 25px;
                    }
                }
                &:last-child {
                    background-color: transparent !important;
                }
            }
        }
        &:nth-child(1) {
            background: #D2FBE7;
            .slide-title {
                color: #15B768;
            }
            .btn-black {
                background: #15B768;
                border-color: #15B768;
            }
            .panel {
                li {
                    background-color: #15B768;
                    &::after {
                        background-color: #15B768;
                    }
                }
            }
            .custom-pagination {
                .active {
                    background-color: #15B768;
                }
            }
            .absolute-block {
                position: absolute;
                width: 312px;
                top: 36px;
                right: -123px;
                border-radius: 30px;
                background: #FFF;
                box-shadow: 0px 10.022222518920898px 25.82962989807129px 0px rgba(0, 0, 0, 0.03), 0px 47.377777099609375px 100.97036743164062px 0px rgba(0, 0, 0, 0.04), 0px 123px 317px 0px rgba(0, 0, 0, 0.07);
                @media (min-width: 1400px) {
                    top: -60px;
                }
                @media (min-width: 1800px) {
                    top: 36px;
                }
                @media (max-width: 1399px) {
                    position: static;
                    margin-top: 15px;
                }
            }
            .m-accordionImages__screens__item {
                &:nth-child(1),
                &:nth-child(2) {
                    background: #F5F6F9;
                    border-radius: 60px;
                }
            }
        }
        &:nth-child(2) {
            background: #FFF5D1;
            .slide-title {
                color: #FFC100;
            }
            .btn-black {
                background: #FFC100;
                border-color: #FFC100;
            }
            .panel {
                li {
                    background-color: #FFC100;
                    &::after {
                        background-color: #FFC100;
                    }
                }
            }
            .custom-pagination {
                .active {
                    background-color: #FFC100;
                }
            }
            .m-accordionImages__screens__item {
                &:nth-child(3) {
                    background: #F5F6F9;
                    border-radius: 60px;
                }
            }
        }
        &:nth-child(3) {
            background: #E7E7FD;
            .slide-title {
                color: #140867;
            }
            .btn-black {
                background: #140867;
                border-color: #140867;
            }
            .panel {
                li {
                    background-color: #140867;
                    &::after {
                        background-color: #140867;
                    }
                }
            }
            .custom-pagination {
                .active {
                    background-color: #140867;
                }
            }
            .m-accordionImages__screens__item {
                &:nth-child(2) {
                    background: #F5F6F9;
                    border-radius: 60px;
                }
            }
        }
        .custom-pagination {
            position: absolute;
            left: 60px;
            display: flex;
            flex-direction: column;
            gap: 14px;
            top: 40%;
            span {
                width: 32px;
                height: 32px;
                border-radius: 50%;
            }
            .active {
                color: #fff;
            }
            @media (max-width: 767px) {
                top: 15px;
                flex-direction: row;
                left: calc(50% - 62px);
                display: none !important;
            }
        }
    }
    .slide-wrap {
        display: flex;
        // height: 500px;
        .info-box {
            flex: 0 0 510px;
            @media (max-width: 767px) {
                flex: 0 0 100%;
            }
        }
        .img-box {
            padding-left: 50px;
            position: relative;
            @media (min-width: 1400px) {
                padding-left: 50px;
            }
            @media (min-width: 1800px) {
                padding-left: 150px;
            }
            @media (max-width: 1199px) {
                display: none;
            }
            .absolute-block {
                position: absolute;
                width: 312px;
                top: 36px;
                right: -123px;
                border-radius: 30px;
                background: #FFF;
                box-shadow: 0px 10.022222518920898px 25.82962989807129px 0px rgba(0, 0, 0, 0.03), 0px 47.377777099609375px 100.97036743164062px 0px rgba(0, 0, 0, 0.04), 0px 123px 317px 0px rgba(0, 0, 0, 0.07);
                @media (min-width: 1400px) {
                    top: -60px;
                }
                @media (min-width: 1800px) {
                    top: 36px;
                }
                @media (max-width: 1399px) {
                    position: static;
                    margin-top: 15px;
                }
            }
        }
        .live-img-wrap {
            padding: 46px 86px;
            border-radius: 60px;
            background: #FFF;
            @media (max-width: 1399px) {
                padding: 15px;
            }
        }
    }
}
.faq-custom {
    margin-top: 200px;
    @media (max-width: 991px) {
        margin-top: 100px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .subtitle {
        color: #000;
        /* tag */
        font-size: 16px;
        font-style: normal !important;
        font-weight: 450;
        line-height: 150%;
        text-transform: uppercase;
        text-align: center;
        font-family: $font-primary;
    }
    .title {
        h2 {
            color: #000;
            text-align: center;
            /* h2 */
            font-family: $font-primary;
            font-size: 56px;
            font-style: normal;
            font-weight: 450;
            line-height: 120%;
            @media (max-width: 991px) {
                font-size: 36px;
            }
        }
    }
    .accordion {
        cursor: pointer;
        width: 100%;
        text-align: left;
        outline: none;
        transition: 0.4s;
        font-size: 20px;
        font-weight: 450;
        line-height: 160%;
        font-family: $font-primary;
        margin-top: 32px;
        display: flex;
        justify-content: space-between;
        // border-radius: 30px;
        // background: #FAFAFA;
        padding: 32px;
        align-items: center;
        &::after {
            content: '';
            background-image: url('../img/arrow-faq.svg');
            display: block;
            width: 24px;
            height: 24px;
            margin-left: 5px;
            flex: 0 0 24px;
        }
        &.active {
            padding-bottom: 16px;
            // background: #D8E7FF;
            // border-radius: 30px 30px 0 0;
            &::after {
                transform: rotate(180deg);
            }
        }
    }
    .panel {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.1s ease-out;
        padding-left: 32px;
        padding-right: 32px;
        color: #292929;
        font-size: 16px;
        &.active {
            padding-bottom: 16px;
            background: #D8E7FF;
            border-radius: 0 0 30px 30px;
        }
    }
    .faq-wrap {
        column-count: 2;
        margin-top: 60px;
        // display: grid;
        // grid-template-columns: repeat(2, 1fr);
        // grid-gap: 20px 40px;
        @media (max-width: 767px) {
            // grid-template-columns: repeat(1, 1fr);
            column-count: 1;
        }
        .faq-item {
            margin-bottom: 20px;
            background: #fafafa;
            border-radius: 30px;
            .accordion {
                margin-top: 0;
            }
            &.active {
                background: #D8E7FF;
            }
            // &:first-child {
            //     .accordion {
            //         margin-top: 0;
            //     }
            // }
            &:nth-child(4) {
                break-after: column;
            }
        }
    }
}
.pricing {
    padding-top: 100px;
    @media (max-width: 991px) {
        padding-top: 100px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .subtitle {
        font-family: $font-primary;
        font-size: 16px;
        font-style: normal !important;
        font-weight: 450;
        line-height: 150%;
        text-transform: uppercase;
        margin-bottom: 12px;
    }
    .title {
        h2 {
            color:  #000;
            font-family: $font-primary;
            font-size: 56px;
            font-style: normal;
            font-weight: 450;
            line-height: 120%;
            margin-bottom: 16px;
            @media (max-width: 991px) {
                font-size: 36px;
            }
        }
    }
    .title-block {
        display: flex;
        align-items: end;
        gap: 113px;
        @media (max-width: 991px) {
            flex-direction: column;
            gap: 15px;
            align-items: flex-start;
        }
        .info {
            max-width: 620px;
        }
    }
    .pricing-wrap {
        margin-top: 60px;
        display: flex;
        gap: 40px;
        @media (max-width: 991px) {
            flex-direction: column;
        }
        .pricing-box {
            padding: 40px;
            border-radius: 60px;
            .tab_item { 
                display: none;
                @media (max-width: 991px) {
                    text-align: center;
                } 
            }
            .tab_item:first-child { display: block; }
            .btn-wrap {
                margin-top: 24px;
                margin-bottom: 40px;
                .btn {
                    // padding: 16px 32px;
                    padding: 10px;
                    display: inline-flex;
                    gap: 12px;
                    align-items: center;
                    &:hover {
                        svg {
                            path {
                                fill: #fff;
                            }
                        }
                    }
                }
            }
            .items {
                .item-title-box {
                    display: flex;
                    gap: 12px;
                    align-items: center;
                    .item-title {
                        color: #000;
                        /* Subtitles */
                        font-family: $font-primary;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 450;
                        line-height: 160%; /* 32px */
                    }
                    margin-bottom: 16px;
                }
                .item-text {
                    color: #292929;

                    /* body 2 */
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 390;
                    line-height: 150%; /* 27px */
                    p {
                        margin-bottom: 16px;
                    }
                }
            }
            &.free {
                background: #FFC9A0;
                flex: 0 0 400px;
                .title-box {
                    display: flex;
                    gap: 10px;
                    align-items: baseline;
                    .block-title {
                        color: #000;
                        font-family: $font-primary;
                        font-size: 40px;
                        font-style: normal;
                        font-weight: 450;
                        line-height: 120%;
                    }
                }
                @media (max-width: 991px) {
                    .items {
                        columns: 2;
                    }
                }
                @media (max-width: 575px) {
                    .items {
                        columns: 1;
                    }
                }
            }
            &.some-price {
                background: #F5F6F9;
                width: 100%;
                .items {
                    columns: 2;
                    @media (max-width: 991px) {
                        text-align: left;
                    }
                    @media (max-width: 575px) {
                        columns: 1;
                    }
                    .item-text {
                        max-width: 320px;
                    }
                }
                .tabs-custom {
                    display: inline-flex;
                    background: #fff;
                    border-radius: 100px;
                    @media (max-width: 991px) {
                        flex-direction: column;
                        display: flex;
                        text-align: center;
                        // margin-top: 60px;
                        border-radius: 20px;
                    }
                    .tab-button {
                        padding: 9px 24px;
                        color: #000;
                        font-size: 20px;
                        font-weight: 450;
                        font-family: $font-primary;
                        &.active {
                            background: #E7E7FD;
                            border-radius: 100px;
                            @media (max-width: 991px) {
                                border-radius: 20px;
                            }
                        }
                        &:nth-child(2) {
                            &.active {
                                background: #fff5d1;
                            }
                        }
                        &:nth-child(3) {
                            &.active {
                                background: #d2fbe7;
                            }
                        }
                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
                .popular {
                    position: absolute;
                    top: 40px;
                    right: 40px;
                    font-size: 18px;
                    text-transform: uppercase;
                    font-weight: 450;
                    display: inline-flex;
                    gap: 8px;
                    padding: 6px 16px;
                    border-radius: 40px;
                    // background: #E7E7FD;
                    align-items: center;
                    font-family: $font-primary;
                    img {
                        height: 24px;
                    }
                    @media (max-width: 991px) {
                        position: static;
                        margin-top: 24px;
                    }
                }
            }
        }
    }
}
.top-block-oops {
    text-align: center;
    #lottie_oops {
        height: 300px;
        margin-top: 50px;
    }
    .title-block {
        width: 80%;
        margin: auto;
    }
    .btn-wrap {
        justify-content: center;
    }
}
.slider_offer {
    &.active-scroll {
        position: sticky;
        top: 100px;
    }
}

.stacking-slide {
    padding: 3rem;
    // box-shadow: 0 0px 50px -12px rgba(0, 0, 0, 0.25);
    @for $i from 0 through 10 {
      &:nth-child(#{$i}) {
        background-color: hsl(-30 + ($i * 30), 100%, 88%);
        // top: ($i * 250px);
      }
    //   top: ($i * 50px);
    }
}
.stacking-slide {
    // height: 100vh;
    min-height: 620px;
    width: 100%;
    position: sticky;
    top: 100px;
    margin-bottom: 100px;
    // top: 0;
    
    &:nth-last-child(2) {
        background: #fff5d1;
        top: 150px;
    }
    &:nth-last-child(3) {
        background: #d2fbe7;
        // top: 200px;
    }

    // Not needed if 100vh
    &:nth-last-child(1) {
    //   height: 100vh;
      background: #e7e7fd;
      top: 200px;
    }
    @media (max-width: 767px) {
        height: auto;
        margin-bottom: 0;
    }
}
