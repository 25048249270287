.btn {
    padding: 10px;
    border-radius: 50px;
    display: inline-block;
    font-family: $font-primary;
    &.btn-black {
        background: #000;
        color: #fff;
        border: 1px solid #000;
        &:hover {
            background: transparent;
            color: #000;
        }
    }
    &.btn-outline {
        background: transparent;
        color: #000;
        border: 1px solid #000;
        &:hover {
            background: #000;
            color: #fff;
        }
    }
}