[data-aos="custom-slider"] {
    opacity: 0;
    transition-property: transform, opacity;
  
    &.aos-animate {
      opacity: 1;
    }
  
    @media screen and (min-width: 768px) {
      transform: translateX(300px);
  
      &.aos-animate {
        transform: translateX(0);
      }
    }
}
[data-aos="custom-slider"] {
    opacity: 0;
    transition-property: transform, opacity;
  
    &.aos-animate {
      opacity: 1;
    }
  
    @media screen and (min-width: 768px) {
      transform: translateX(300px);
  
      &.aos-animate {
        transform: translateX(0);
      }
    }
}
@keyframes text {
    from {
        transform: translateX(0);
      }
    to {
        transform: translateX(calc(-100% - 20px));
    }
}