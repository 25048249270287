/* ====================
# FONTS
==================== */
* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
body {
	font-family: $font-primary;
}
@mixin font-face($font-family, $file-name, $file-path, $weight, $style: normal) {
	@font-face {
		font-family: $font-family;
		font-weight: $weight;
		font-style: $style;
		font-display: swap;
		src: url('#{$file-path}.eot');
		src: local('#{$file-name}'),
		url('#{$file-path}.eot?#iefix') format('embedded-opentype'),
		url('#{$file-path}.woff') format('woff'),
		url('#{$file-path}.ttf') format('truetype');
	}
}
$f_family: 'Circular';
$f_path: '../fonts/';
// @include font-face($f_family, 'Circular Std Book', $f_path + 'CircularStd-Book', 400);
