@media print,screen and (min-width: 40.0625em) {
  .o-accordions__spacer {
      height:100vh;
      margin-top: 10vh
  }
}
.o-accordions{
  position: relative;
  margin-top: 40px;
}

.o-accordions__container {
  // padding: 8vh 0;
  // height: 100vh;
  // position: sticky;
  // top: 0;
  // overflow: hidden
}

// @media print,screen and (min-width: 40.0625em) {
//   .o-accordions__container {
//       height:100vh;
//       margin-bottom: 10vh;
//       // padding: 14vh 0
//   }
// }
.o-accordions__spacer {
  height: 100vh;
  margin-top: 50vh;
}

.o-accordions__container+.o-accordions__spacer {
  margin-top: -90vh
}

.o-accordions__inner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.o-accordions__main {
  width: 100%;
  padding: 0;
  .o-accordions__main__inner {
    width: 100%;
  }
}

@media print,screen and (min-width: 40.0625em) {
  .o-accordions__main {
      width:auto;
      flex: 1;
      // padding: 0 0 1.875rem 1.875rem
  }
}

@media print,screen and (min-width: 64.0625em) {
  .o-accordions__main {
      // padding:8vh .9375rem 6.25rem 8.625rem;
      display: flex
  }
}

@media screen and (min-width: 115.5em) {
  .o-accordions__main {
      padding-left:calc(((100vw - 106.875rem) / 2) + 6.563333rem)
  }
}

.o-accordions__side {
  width: 100%;
  display: flex;
  align-items: center;
}

@media print,screen and (min-width: 40.0625em) {
  .o-accordions__side {
      // width:60%
      // display: none;
  }
}

@media print,screen and (min-width: 64.0625em) {
  .o-accordions__side {
      width:43%
  }
}

// @media screen and (min-width: 115.5em) {
//   .o-accordions__side {
//       padding-right:5vw
//   }
// }

.o-accordions__side__image {
  width: 45%
}

@media print,screen and (min-width: 40.0625em) {
  .o-accordions__side__image {
      width:auto
  }

  .o-accordions__side__image:not(:last-child) {
      margin-bottom: 5.3125rem
  }
}

.o-accordions__side__image:not(:last-child) img {
  transform: scaleX(-1)
}

@media print,screen and (min-width: 40.0625em) {
  .o-accordions__side__image:not(:last-child) img {
      transform:none
  }
}

.o-accordions__headline {
  line-height: 1.1;
  margin: 0 0 .5em;
  font-weight: 300;
}

@media print,screen and (min-width: 64.0625em) {
  .o-accordions__headline {
      font-size:5vmin
  }
}

@media screen and (min-width: 120.0625em) {
  .o-accordions__headline {
      font-size:3.125rem
  }
}

.o-accordions__text {
  font-size: 1.25rem;
  font-weight: 300;
  margin-bottom: 2.5em
}

@media print,screen and (min-width: 64.0625em) {
  .o-accordions__text {
      font-size:2.4vmin;
      max-width: 66.5vmin
  }
}

@media screen and (min-width: 120.0625em) {
  .o-accordions__text {
      font-size:1.5rem;
      max-width: 39rem
  }
}

.o-accordions:not(.isStatic) {
  margin-bottom: 0
}

@media print,screen and (min-width: 40.0625em) {
  .o-accordions:not(.isStatic) {
      margin-bottom:1.875rem
  }
}

@media screen and (min-width: 120.0625em) {
  .o-accordions:not(.isStatic) .o-accordions__container {
      overflow:visible
  }
}

.o-accordions.isStatic {
  margin-top: 0;
  margin-bottom: 0
}

@media print,screen and (min-width: 40.0625em) {
  .o-accordions.isStatic {
      margin-top:1.875rem;
      margin-bottom: 1.875rem;
      padding: 0 0 5rem;
      background: linear-gradient(to right, #E7E8EA, #C7C8CC)
  }
}

.o-accordions.isStatic+section {
  margin-top: 0
}

@media print,screen and (min-width: 40.0625em) {
  .o-accordions.isStatic+section {
      margin-top:1.875rem
  }
}

.o-accordions.isStatic .o-accordions__container+.o-accordions__spacer {
  margin-top: 0
}

.o-accordions.isStatic .o-accordions__container {
  padding: 8vh 5vw 5vw;
  margin-bottom: 5vw;
  height: auto;
  max-height: 100vh;
  background: linear-gradient(to right, #E7E8EA, #C7C8CC)
}

@media print,screen and (min-width: 40.0625em) {
  .o-accordions.isStatic .o-accordions__container {
      max-height:unset;
      height: 100vh;
      padding: 14vh 0 0;
      margin-bottom: 0
  }
}

@media print,screen and (min-width: 40.0625em) {
  .o-accordions.isStatic .o-accordions__main {
      order:2;
      padding: 0 1.875rem 0 .9375rem
  }
}

@media print,screen and (min-width: 64.0625em) {
  .o-accordions.isStatic .o-accordions__main {
      padding:0 8.625rem 0 5%
  }
}

@media screen and (min-width: 115.5em) {
  .o-accordions.isStatic .o-accordions__main {
      padding:1.2vmin calc(((100vw - 106.875rem) / 2) + 6.563333rem) 0 7%
  }
}

@media screen and (min-width: 120.0625em) {
  .o-accordions.isStatic .o-accordions__main {
      padding-top:2rem
  }
}

.o-accordions.isStatic .o-accordions__headline {
  max-width: 90%
}

.o-accordions.isStatic .o-accordions__side {
  padding-top: 3.75rem;
  margin: 0;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%
}

@media print,screen and (min-width: 40.0625em) {
  .o-accordions.isStatic .o-accordions__side {
      width:50%;
      margin: 0;
      display: block;
      padding: 3.125rem 1.875rem 0 0
  }
}

@media print,screen and (min-width: 64.0625em) {
  .o-accordions.isStatic .o-accordions__side {
      padding:0
  }
}

@media print,screen and (max-width: 40.06125em) {
  .o-accordions.isStatic .m-accordionImages {
      width:100%;
      margin: 0;
  }
}

.o-accordions.isStatic .m-accordionImages__screens {
  overflow: visible
}

.o-accordions.isStatic .m-accordionImages__screens__item {
  transform: none
}

.o-accordions.isStatic .m-accordionImages__screens__item:not(.isActive) {
  transition: opacity .35s ease-in-out
}

.o-accordions.isStatic .m-accordions {
  max-width: 33.125rem
}

@media print,screen and (min-width: 64.0625em)and (max-width: 120.06125em) {
  .o-accordions.isStatic .m-accordions {
      max-width:100%
  }
}

// @media print,screen and (min-width: 64.0625em) {
//   .o-accordions.isStatic .m-accordions__item {
//       padding-left:5.3vmin
//   }
// }

// @media screen and (min-width: 120.0625em) {
//   .o-accordions.isStatic .m-accordions__item {
//       padding-left:2.875rem
//   }
// }

.o-accordions.isStatic .m-accordions__item:not(.isActive) {
  opacity: 1
}

.o-accordions.isStatic .m-accordions__item__icon svg path {
  fill: #1d9cff
}

.m-accordions {
  margin: 0;
  padding: 0;
  list-style: none;
  max-width: 41.875rem
}

@media print,screen and (min-width: 64.0625em)and (max-width: 120.06125em) {
  .m-accordions {
      max-width:71vmin
  }
}

.m-accordions__item {
  position: relative;
  // padding-left: 2.0625rem;
  transition: opacity .35s ease-in-out
}

@media print,screen and (min-width: 64.0625em) {
  .m-accordions__item {
      // padding-left:6vmin
  }
}

// @media screen and (min-width: 120.0625em) {
//   .m-accordions__item {
//       padding-left:4.125rem
//   }
// }

.m-accordions__item:not(:last-child) {
  margin-bottom: 6.6vw
}

@media print,screen and (min-width: 40.0625em) {
  .m-accordions__item:not(:last-child) {
      margin-bottom:3.5625rem
  }
}

@media print,screen and (min-width: 64.0625em)and (max-width: 120.06125em) {
  .m-accordions__item:not(:last-child) {
      margin-bottom:5.2vmin
  }
}

.m-accordions__item:not(.isActive) {
  cursor: pointer
}

.m-accordions__item__icon {
  position: absolute;
  left: 0;
  top: -0.1875rem
}

@media print,screen and (min-width: 40.0625em) {
  .m-accordions__item__icon {
      top:.25rem
  }
}

@media print,screen and (min-width: 64.0625em)and (max-width: 120.06125em) {
  .m-accordions__item__icon {
      top:0
  }
}

.m-accordions__item__icon .a-icon {
  display: block
}

.m-accordions__item__icon svg {
  display: block;
  width: auto;
  height: 1.375rem
}

@media print,screen and (min-width: 64.0625em) {
  .m-accordions__item__icon svg {
      height:3vmin
  }
}

@media screen and (min-width: 120.0625em) {
  .m-accordions__item__icon svg {
      height:1.875rem
  }
}

.m-accordions__item__icon svg path {
  fill: #9d55ff
}

.m-accordions__item__title{
  font-size: 1.1875rem;
  font-weight: 300;
  margin: 0;
}
.m-accordions__item__title p{
  margin: 0;
}

@media print,screen and (min-width: 64.0625em) {
  .m-accordions__item__title {
      font-size:3.4vmin
  }
}

@media screen and (min-width: 120.0625em) {
  .m-accordions__item__title {
      font-size:2rem
  }
}

.m-accordions__item__title:not(:last-child) {
  margin-bottom: 0
}

.m-accordions__item__text {
  overflow: hidden;
  transition: max-height .35s ease-in-out;
  font-size: .9375rem;
  line-height: 1.25;
  font-weight: 300
}

@media print,screen and (min-width: 64.0625em) {
  .m-accordions__item__text {
      font-size:2.35vmin;
      line-height: inherit
  }
}

@media screen and (min-width: 120.0625em) {
  .m-accordions__item__text {
      font-size:1.5rem
  }
}

.m-accordions__item__text p:first-child {
  // margin-top: 3.5vw;
  margin-bottom: 0;
}

// @media print,screen and (min-width: 40.0625em) {
//   .m-accordions__item__text p:first-child {
//       margin-top:1em
//   }
// }

// .m-accordions__item:not(.isActive) {
//   opacity: .4
// }

.m-accordions__item:not(.isActive) .m-accordions__item__text {
  max-height: 0
}

.m-accordionImages {
  position: relative;
  max-width: 100%
}
.m-accordionImages img{
  max-width: 100%;
}
@media print,screen and (max-width: 40.06125em) {
  .m-accordionImages {
      // width:122vw !important;
      // max-width: 122vw;
      // margin-left: -16vw;
      // margin-top: 5vh;
      // margin-bottom: -67%;
      // display: none !important;
      margin-top: 50px;
  }
}

@media print,screen and (min-width: 40.0625em) {
  .m-accordionImages {
      max-width:100%;
      width: 120%;
      max-width: 120%
  }
}

@media print,screen and (min-width: 64.0625em) {
  .m-accordionImages {
      width:37vw;
      max-width: 100%;
      margin-left: auto
  }
}

.m-accordionImages__screens {
  margin: 0;
  padding: 0;
  list-style: none;
  border-radius: 1.25rem;
  // overflow: hidden;
  // position: absolute;
  // width: 50%;
  left: 25%;
  top: 3.35%;
  height: 86%
}

@media print,screen and (min-width: 64.0625em) {
  .m-accordionImages__screens {
      border-radius:2.1875rem
  }
}

.m-accordionImages__screens__item {
  // position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: transform .35s ease-in-out,opacity .35s ease-in-out,margin .35s ease-in-out;
  will-change: transform
}

.m-accordionImages__screens__item img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover
}

.m-accordionImages__screens__item {
  display: none;
}
.m-accordionImages__screens__item:not(:first-child) {
  transform: translate3d(0, 101%, 0);
  // display: none;
}

.m-accordionImages__screens__item.isPrev {
  position: absolute
}

.m-accordionImages__screens__item.isActive {
  transform: translate3d(0, 0, 0);
  display: block;
}

.m-accordionImages.isHub {
  width: 100%;
  height: 40vw;
  margin-left: 0
}

.m-accordionImages.isHub .m-accordionImages__screens {
  border-radius: 0;
  left: -1.15vw;
  top: 0;
  width: 100%;
  height: 100%
}

@media print,screen and (min-width: 40.0625em) {
  .m-accordionImages.isHub .m-accordionImages__screens {
      left:-13%;
      width: 111%
  }
}

.m-accordionImages.isHub .m-accordionImages__screens__item {
  opacity: 0
}

.m-accordionImages.isHub .m-accordionImages__screens__item img {
  object-fit: contain;
  object-position: left center
}

.m-accordionImages.isHub .m-accordionImages__screens__item.isActive {
  opacity: 1
}

.m-accordionImages.isHub .m-accordionImages__screens__item:not(:first-child) {
  transform: none
}

.m-accordionImages.isHub .m-accordionImages__screens__item.isHidden {
  opacity: 0
}

.o-environmentallyFriendly__top {
  background: linear-gradient(135deg, #545467 15%, #35354a 60%);
  color: #fff;
  padding: 7vh 0 18vh
}

@media print,screen and (min-width: 64.0625em) {
  .o-environmentallyFriendly__top {
      padding:7.625rem 0 17rem
  }
}

.o-environmentallyFriendly__top>.grid-container {
  max-width: 78.375rem
}

.o-environmentallyFriendly__top__inner {
  display: flex;
  flex-wrap: wrap
}

.o-environmentallyFriendly__main {
  width: 100%;
  margin-bottom: 2.5rem
}

@media print,screen and (min-width: 40.0625em) {
  .o-environmentallyFriendly__main {
      flex:1;
      width: auto;
      padding-top: 2.1875rem;
      margin-bottom: 0
  }
}

@media print,screen and (min-width: 64.0625em) {
  .o-environmentallyFriendly__main {
      padding-top:5.5625rem
  }
}

.o-environmentallyFriendly__side {
  width: 100%
}

@media print,screen and (min-width: 40.0625em) {
  .o-environmentallyFriendly__side {
      width:46%
  }
}

@media print,screen and (min-width: 64.0625em) {
  .o-environmentallyFriendly__sideImage {
      transform:translate3d(-10%, 0, 0)
  }
}

.o-environmentallyFriendly__sideImage img {
  width: 55vw;
  margin-left: -22vw
}

@media print,screen and (min-width: 40.0625em) {
  .o-environmentallyFriendly__sideImage img {
      width:auto;
      margin-left: 0
  }
}

.o-environmentallyFriendly__headline {
  line-height: 1.05;
  font-weight: 300;
  margin: 0 0 .775em;

}

.o-environmentallyFriendly__text {
  font-size: 1.1875rem;
  line-height: 1.35;
  font-weight: 300;
  max-width: 95%;
  margin-bottom: 1.675em
}

@media print,screen and (min-width: 64.0625em) {
  .o-environmentallyFriendly__text {
      font-size:1.875rem
  }
}

.o-environmentallyFriendly__logo img {
  max-width: 11.875rem
}

@media print,screen and (min-width: 64.0625em) {
  .o-environmentallyFriendly__logo img {
      max-width:16.9375rem
  }
}

.o-environmentallyFriendly__video {
  max-width: 93.75rem;
  margin: -6.25rem auto 4.0625rem
}

@media print,screen and (min-width: 64.0625em) {
  .o-environmentallyFriendly__video {
      margin:-13.125rem auto 13.8125rem
  }
}

.o-environmentallyFriendly__video__inner {
  position: relative;
  padding-bottom: 90%
}

@media print,screen and (min-width: 40.0625em) {
  .o-environmentallyFriendly__video__inner {
      padding-bottom:44.25%
  }
}

.o-environmentallyFriendly__video__inner:before {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #707070;
  opacity: .11;
  z-index: 1
}

.o-environmentallyFriendly__video__lightboxLink {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: rgba(53,53,74,.35)
}

.o-environmentallyFriendly__video__lightboxLink .a-icon svg {
  width: 5rem;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  fill: #fff;
  opacity: .6
}

@media print,screen and (min-width: 64.0625em) {
  .o-environmentallyFriendly__video__lightboxLink .a-icon svg {
      width:8.125rem
  }
}

.o-environmentallyFriendly__top {
  background: linear-gradient(135deg, #545467 15%, #35354a 60%);
  color: #fff;
  padding: 7vh 0 18vh
}

@media print,screen and (min-width: 64.0625em) {
  .o-environmentallyFriendly__top {
      padding:7.625rem 0 17rem
  }
}

.o-environmentallyFriendly__top>.grid-container {
  max-width: 78.375rem
}

.o-environmentallyFriendly__top__inner {
  display: flex;
  flex-wrap: wrap
}

.o-environmentallyFriendly__main {
  width: 100%;
  margin-bottom: 2.5rem
}

@media print,screen and (min-width: 40.0625em) {
  .o-environmentallyFriendly__main {
      flex:1;
      width: auto;
      padding-top: 2.1875rem;
      margin-bottom: 0
  }
}

@media print,screen and (min-width: 64.0625em) {
  .o-environmentallyFriendly__main {
      padding-top:5.5625rem
  }
}

.o-environmentallyFriendly__side {
  width: 100%
}

@media print,screen and (min-width: 40.0625em) {
  .o-environmentallyFriendly__side {
      width:46%
  }
}

@media print,screen and (min-width: 64.0625em) {
  .o-environmentallyFriendly__sideImage {
      transform:translate3d(-10%, 0, 0)
  }
}

.o-environmentallyFriendly__sideImage img {
  width: 55vw;
  margin-left: -22vw
}

@media print,screen and (min-width: 40.0625em) {
  .o-environmentallyFriendly__sideImage img {
      width:auto;
      margin-left: 0
  }
}

.o-environmentallyFriendly__headline {
  line-height: 1.05;
  margin-bottom: .775em
}

.o-environmentallyFriendly__text {
  font-size: 1.1875rem;
  line-height: 1.35;
  font-weight: 300;
  max-width: 95%;
  margin-bottom: 1.675em
}

@media print,screen and (min-width: 64.0625em) {
  .o-environmentallyFriendly__text {
      font-size:1.875rem
  }
}

.o-environmentallyFriendly__logo img {
  max-width: 11.875rem
}

@media print,screen and (min-width: 64.0625em) {
  .o-environmentallyFriendly__logo img {
      max-width:16.9375rem
  }
}

.o-environmentallyFriendly__video {
  max-width: 93.75rem;
  margin: -6.25rem auto 4.0625rem
}

@media print,screen and (min-width: 64.0625em) {
  .o-environmentallyFriendly__video {
      margin:-13.125rem auto 13.8125rem
  }
}

.o-environmentallyFriendly__video__inner {
  position: relative;
  padding-bottom: 90%
}

@media print,screen and (min-width: 40.0625em) {
  .o-environmentallyFriendly__video__inner {
      padding-bottom:44.25%
  }
}

.o-environmentallyFriendly__video__inner:before {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #707070;
  opacity: .11;
  z-index: 1
}

.o-environmentallyFriendly__video__lightboxLink {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: rgba(53,53,74,.35)
}

.o-environmentallyFriendly__video__lightboxLink .a-icon svg {
  width: 5rem;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  fill: #fff;
  opacity: .6
}

@media print,screen and (min-width: 64.0625em) {
  .o-environmentallyFriendly__video__lightboxLink .a-icon svg {
      width:8.125rem
  }
}
.m-lightbox {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  pointer-events: none
}

.m-lightbox:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0;
  transition: opacity .25s ease-in-out
}

.m-lightbox__container {
  width: 100%;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  padding: 0 .9375rem
}

.m-lightbox__content {
  max-width: 854px;
  margin: 0 auto;
  opacity: 0;
  transform: scale(0.25);
  will-change: transform;
  transition: transform .25s ease-in-out,opacity .25s ease-in-out
}

.m-lightbox__content__inner {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%
}

.m-lightbox__content__inner iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0
}

.m-lightbox__close {
  position: absolute;
  top: 1.5625rem;
  right: 1.5625rem;
  z-index: 3;
  background: transparent;
  padding: .625rem;
  cursor: pointer;
  opacity: 0;
  transition: opacity .25s ease-in-out
}

.m-lightbox__close .a-icon {
  display: block;
  width: auto;
  height: auto;
  line-height: 1
}

.m-lightbox__close .a-icon svg {
  display: block;
  width: 1.5625rem;
  height: auto;
  fill: #fff
}

.m-lightbox.isActive {
  pointer-events: auto
}

.m-lightbox.isActive::before {
  opacity: .6
}

.m-lightbox.isActive .m-lightbox__close {
  opacity: 1
}

.m-lightbox.isActive .m-lightbox__content {
  transform: none;
  opacity: 1
}

body.lockScroll {
  overflow: hidden
}

@media print,screen and (min-width: 64.0625em) {
  body.lockScroll {
      padding-right:6px
  }
}
.bg-cover {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}


.splide {
  position: sticky;
  top: 100px;
}