$font-primary: 'BrandonText';
$font-secondary: 'Orbitron';
$color-primary: #8FD7DA;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;


$color-h:#121212;
$color-cntnt:#4D4D4D;
$color-rd:#8C0B16;
$color-wht:#fff;
$color-srch-plchldr:#868686;
