.container {
    @media (min-width: 576px) {
        max-width: 540px;
    }
    @media (min-width: 768px) {
        max-width: 720px;
    }
    @media (min-width: 992px) {
        max-width: 960px;
    }
    @media (min-width: 1200px) {
        max-width: 1140px;
    }
    @media (min-width: 1400px) {
        max-width: 1320px;
    }
    @media (min-width: 1800px) {
        max-width: 1720px;
    }
}
.wrap-custom {
    max-width: 1280px;
    margin-right: auto;
    margin-left: auto;
    @media (max-width: 1399px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}